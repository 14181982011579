/* Global Styles */

:root {
  --secondary-color: #e83e8c;
  --corporate-red: #fc777f;
  --corporate-amber: #fcc517;
  --corporate-green: #75ea43;
  --corporate-red-shadow: #feda9d;
  --corporate-amber-shadow: #fc968d;
  --corporate-green-shadow: #a2dce1;
  /* Text */
  --corporate-text-dark: #555555;
  --corporate-text-logo: #636363;
  --corporate-text-h2: #1a20dd;

}
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #ffffff;
  color: #333333;
}

/* App Container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
}

/* Header */
.App-header {
  background-color: #fff; /* Lighter, professional look */
  color: #333;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border for a premium feel */
}

.App-header h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--corporate-text-dark);
}

.App-header h1 {
  margin: 0;
}

.App-header h1 a {
  text-decoration: none;
  color: var(--corporate-text-dark);
}

/* Nav list styles */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px; /* spacing between links */
}

.nav-links li a {
  color: var(--corporate-text-dark);
  text-decoration: none;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-links .current-page {
  color: var(--corporate-text-dark);
  font-weight: 500;
  opacity: 0.8;
}

.App-header h1,
.App-header p {
  position: relative;
  z-index: 1;
  /* remove text-shadow if it exists */
}

@keyframes gradientRotate {
  0% {
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 100% 50%;
  }
}

.App-header p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

/* Hero Section */
.hero {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center; 
  align-items: center;
  text-align: center;
  
  /* Safari / Older Browsers Prefixes */
  /* background: -webkit-linear-gradient(
    135deg,
    var(--corporate-red),
    var(--corporate-amber),
    var(--corporate-green)
  );
  background: linear-gradient(
    135deg,
    var(--corporate-red),
    var(--corporate-amber),
    var(--corporate-green)
  ); */
  background: var(--corporate-red);

  color: #ffffff;
  padding: 100px 20px;
  min-height: 60vh;
}

/* Centers the text within a fixed content width */
.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h2 {
  font-size: 3em;
  margin-bottom: 1em;
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.hero-content p {
  font-size: 1.3em;
  line-height: 1.8;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 400;
}

.hero-content .button {
  color: #ffffff;
  font-weight: 500;
  text-shadow: none;
}

/* Main Content */
.App-main {
  flex: 1;
  width: 100%;
}

/* Service Sections */
.service {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
}

.service:nth-child(even) {
  flex-direction: row-reverse;
}

/* Accent Color Strips */
.service:nth-child(even) {
  /* border-left-color: #e83e8c; */
  border-left-color: var(--corporate-red);
}

.service:nth-child(3n) {
  border-left-color: var(--corporate-green);
}

/* Base Styles for .service-content */
.service-content {
  padding: 20px;
  /* Remove the default border color if present */
}

/* For Odd-Numbered Services */
.service:nth-child(odd) .service-content {
  border-left: 6px solid var(--corporate-red-shadow); /* Vibrant coral color */
}

/* For Even-Numbered Services */
.service:nth-child(even) .service-content {
  border-right: 6px solid var(--corporate-amber-shadow);
}

/* Apply Second Accent Color to Every Third Service */
.service:nth-child(3n) .service-content {
  border-color: var(--corporate-green-shadow); /* Second accent color */
}

/* Adjust for Even Services That Are Also Every Third (e.g., 6th, 12th) */
.service:nth-child(3n):nth-child(even) .service-content {
  border-right-color: var(--corporate-green-shadow);
}

.service-content {
  max-width: 600px;
  width: 60%;
}

.service-content h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: var(--corporate-text-h2);
}

.service-content p {
  font-size: 1.1em;
  color: var(--corporate-text-dark);
  line-height: 1.8;
}

/* Image Placeholder */
.service::before {
  content: '';
  display: block;
  width: 40%;
  height: 250px;
  background-color: var(--corporate-red);
  background-size: cover;
  background-position: center;
  margin-right: 20px;
}

.service:nth-child(even)::before {
  margin-right: 0;
  margin-left: 20px;
  background-color: var(--corporate-amber);
}

.service:nth-child(3n)::before {
  background-color: var(--corporate-green);
}

@media (max-width: 768px) {
  .service {
    flex-direction: column;
    padding: 40px 20px;
  }

  .service:nth-child(even) {
    flex-direction: column;
  }

  .service-content {
    width: 100%;
  }

  .service::before,
  .service:nth-child(even)::before {
    width: 100%;
    height: 200px;
    margin: 0 0 20px 0;
  }

  /* Mobilize borders and layout */
  .service:nth-child(odd) .service-content,
  .service:nth-child(even) .service-content {
    border: 0;
  }

  .service::before {
    /* Adjust or remove accent lines for mobile if necessary */
  }

  .App-header h1 {
    font-size: 2.5em;
  }

  .App-main {
    padding: 30px 15px;
  }

  .service {
    padding: 25px;
    margin: 25px 0;
  }

  .service h2 {
    font-size: 1.5em;
  }

  .hero {
    padding: 40px 0;
    min-height: auto;
    width: 100%;
    overflow: hidden;
  }

  .hero-content {
    width: 90%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .button-group {
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin: 2em auto 0;
    padding: 0;
  }

  .button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
  }

  .hero-content h2 {
    font-size: 1.8em;
    margin-bottom: 0.8em;
  }

  .hero-content p {
    font-size: 1.1em;
    line-height: 1.6;
  }

  .button-group {
    flex-direction: column;
    gap: 15px;
  }

  .button {
    width: 100%;
    text-align: center;
  }

  .App-header {
    flex-direction: column;
    padding: 15px;
    align-items: flex-start;
  }

  .App-header h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .nav-links li {
    width: 100%;
  }

  .nav-links li a {
    display: block;
    padding: 8px 0;
  }

  .hero {
    padding: 30px 15px;
  }

  .hero-content h2 {
    font-size: 1.8em;
    margin-bottom: 0.8em;
  }

  .hero-content p {
    font-size: 1em;
    line-height: 1.5;
  }

  .logo-image {
    height: 30px;
  }
}

/* Footer */
.App-footer {
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-cta {
  margin-bottom: 40px;
  text-align: center;
}

.footer-cta h3 {
  color: var(--corporate-text-dark);
  font-size: 1.8em;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.App-footer p {
  margin: 0;
  font-size: 0.9em;
}

.footer-content p {
  color: var(--corporate-text-dark);
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .App-footer {
    padding: 30px 0;
  }
  
  .footer-content {
    padding: 0 20px;
  }
  
  .footer-cta h3 {
    font-size: 1.5em;
  }
}

/* Buttons (Optional for Future Use) */
.button {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

/* Come up with a better hover effect */
.button:hover {
  background-color: #e83e8c;
}

.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 2em;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  border: 2px solid transparent; /* Add border */
}

.button.primary {
  background-color: var(--corporate-red);
  color: #ffffff;
}

.button.secondary {
  background-color: var(--corporate-amber);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: borderColorLoop 3s linear infinite; /* Apply animation */
  border-color: var(--corporate-red); /* Ensure initial border color is set */
}

/* EPC Hero Button Colors */
.product-hero .button.primary {
  background-color: var(--corporate-red);
}

.product-hero .button.secondary {
  background-color: var(--corporate-amber);
  color: var(--corporate-text-dark);
}

/* Consultancy Hero Button Colors */
.consultancy-hero .button.primary {
  background-color: var(--corporate-amber);
  color: var(--corporate-text-dark);
}

.consultancy-hero .button.secondary {
  background-color: var(--corporate-green);
  color: var(--corporate-text-dark);
}

.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 2em;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  border: 2px solid rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.2s ease;
}

.button.primary {
  border-color: rgba(255, 255, 255, 0.9);
}

.button.secondary {
  border-color: rgba(255, 255, 255, 0.9);
  background-color: var(--corporate-red);
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.button.secondary:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
}

.App-footer .button {
  border-color: var(--corporate-text-h2);
  color: var(--corporate-text-h2);
}

.App-footer .button:hover {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
}

/* Footer Button Styles */
.App-footer .button {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
  border: 2px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.App-footer .button:hover {
  background-color: #ffffff;
  color: var(--corporate-text-h2);
  border-color: var(--corporate-text-h2);
}

/* Responsive Design */
.logo {
  margin-bottom: 20px;
}

.App-header .logo svg {
  max-width: 100%;
  height: auto;
}

/* Add to existing CSS */

/* Product Hero Modifications */
.product-hero {
  min-height: 40vh;
  background: linear-gradient(135deg, var(--corporate-red), var(--corporate-amber));
}

/* Features Section */
.features {
  padding: 80px 20px;
  background: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 30px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
}

.feature-card h4 {
  font-size: 1.5em;
  color: var(--corporate-text-h2);
  margin-bottom: 20px;
}

/* Pricing Section */
.pricing {
  padding: 80px 20px;
  background: #f8f9fa;
}

.pricing h3 {
  text-align: center;
  color: var(--corporate-text-h2);
  margin-bottom: 40px;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.pricing-card {
  padding: 50px 40px 100px; /* Increased bottom padding */
  min-height: 500px; /* Set minimum height */
  display: flex;
  flex-direction: column;
  position: relative;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.pricing-card h4 {
  font-size: 1.8em;
  color: var(--corporate-text-h2);
  margin-bottom: 15px;
}

.pricing-card .price {
  font-size: 2.2em;
  color: var(--corporate-text-dark);
  margin: 25px 0;
  font-weight: 600;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  flex-grow: 1;
}

.pricing-card li {
  padding: 12px 0 12px 30px;
  position: relative;
  text-align: left;
  line-height: 1.6;
}

.pricing-card li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--corporate-amber);
  font-size: 1.5em;
  line-height: 1;
}

.pricing-card.featured {
  transform: scale(1.05);
  border: none;
  background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(135deg, var(--corporate-amber), var(--corporate-red)) border-box;
  border: 2px solid transparent;
}

.pricing-card .button {
  display: block;
  width: 80%;
  margin: 30px auto 0;
  padding: 12px 24px;
  border: 2px solid var(--corporate-text-h2);
  border-radius: 4px;
  font-weight: 500;
  color: var(--corporate-text-h2);
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.pricing-card .button:hover {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
  transform: translateX(-50%); /* Maintain horizontal centering */
}

.pricing-card.featured .button:hover {
  background-color: transparent;
  color: var(--corporate-text-h2);
  transform: translateX(-50%);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .pricing-card.featured {
    transform: none;
  }
  
  .feature-grid,
  .pricing-grid {
    grid-template-columns: 1fr;
  }
  
  .pricing-card {
    padding: 30px 20px 90px;
    min-height: auto;
  }
}

/* Add to existing CSS */

.consultancy-hero {
  background: linear-gradient(135deg, var(--corporate-amber), var(--corporate-green));
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.service-card {
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 30px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
}

.service-card h4 {
  font-size: 1.5em;
  color: var(--corporate-text-h2);
  margin-bottom: 20px;
}

.service-details {
  list-style: none;
  padding: 0;
  margin: 25px 0 0;
}

.service-details li {
  padding: 12px 0 12px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.service-details li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--corporate-amber);
}

.methodology {
  background: #f8f9fa;
  padding: 80px 20px;
}

.methodology-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.step {
  text-align: center;
  padding: 20px;
}

.step-number {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: var(--corporate-amber);
  color: #fff;
  margin-bottom: 15px;
}

.engagement-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.engagement-card {
  padding: 50px 40px 100px; /* Increased bottom padding */
  min-height: 500px; /* Set minimum height */
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.engagement-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.engagement-card h4 {
  font-size: 1.8em;
  color: var(--corporate-text-h2);
  margin-bottom: 15px;
}

.engagement-card .price {
  font-size: 2.2em;
  color: var(--corporate-text-dark);
  margin: 25px 0;
  font-weight: 600;
}

.engagement-card ul {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  flex-grow: 1;
}

.engagement-card li {
  padding: 12px 0 12px 30px;
  position: relative;
  text-align: left;
  line-height: 1.6;
}

.engagement-card li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--corporate-amber);
  font-size: 1.5em;
  line-height: 1;
}

.engagement-card.featured {
  transform: scale(1.05);
  border: none;
  background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(135deg, var(--corporate-amber), var(--corporate-green)) border-box;
  border: 2px solid transparent;
}

.engagement-card .button {
  display: block;
  width: 80%;
  margin: 30px auto 0;
  padding: 12px 24px;
  border: 2px solid var(--corporate-text-h2);
  border-radius: 4px;
  font-weight: 500;
  color: var(--corporate-text-h2);
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.engagement-card .button:hover {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
  transform: translateX(-50%); /* Maintain horizontal centering */
}

.engagement-card.featured .button:hover {
  background-color: transparent;
  color: var(--corporate-text-h2);
  transform: translateX(-50%);
}

/* Section Headers */
.services h3,
.methodology h3,
.engagement h3 {
  font-size: 2.5em;
  color: var(--corporate-text-h2);
  margin-bottom: 50px;
  text-align: center;
}

@media (max-width: 768px) {
  .service-grid,
  .methodology-steps,
  .engagement-grid {
    grid-template-columns: 1fr;
  }
  
  .engagement-card.featured {
    transform: none;
  }
  
  .pricing-card,
  .engagement-card {
    min-height: auto;
    padding: 30px 20px 90px;
  }
}

/* Card Button Styles */
.pricing-card .button,
.engagement-card .button {
  display: block;
  width: 80%;
  margin: 30px auto 0;
  padding: 12px 24px;
  border: 2px solid var(--corporate-text-h2);
  border-radius: 4px;
  font-weight: 500;
  color: var(--corporate-text-h2);
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.2s ease, color 0.2s ease;
}

.pricing-card .button:hover,
.engagement-card .button:hover {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
  transform: translateX(-50%); /* Maintain horizontal centering */
}

.pricing-card.featured .button,
.engagement-card.featured .button {
  background-color: var(--corporate-text-h2);
  color: #ffffff;
}

.pricing-card.featured .button:hover,
.engagement-card.featured .button:hover {
  background-color: transparent;
  color: var(--corporate-text-h2);
  transform: translateX(-50%);
}

/* About Page Styles */
.about-hero {
  background: linear-gradient(135deg, var(--corporate-green), var(--corporate-amber));
}

.story {
  padding: 80px 20px;
  background: #ffffff;
}

.story p {
  font-size: 1.2em;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.team {
  padding: 80px 20px;
  background: #f8f9fa;
}

.founders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
}

.founder-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 40px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
}

.founder-card:hover {
  transform: translateY(-5px);
}

.founder-image {
  width: 200px;
  height: 200px;
  margin: 0 auto 30px;
  border-radius: 50%;
  overflow: hidden;
}

.founder-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.founder-card h4 {
  font-size: 1.8em;
  color: var(--corporate-text-h2);
  margin-bottom: 10px;
}

.founder-card .title {
  color: var(--corporate-amber);
  font-weight: 500;
  margin-bottom: 20px;
}

.founder-card .bio {
  margin-bottom: 30px;
  line-height: 1.6;
}

.founder-card .experience ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.founder-card .experience li {
  padding: 8px 0 8px 25px;
  position: relative;
}

.founder-card .experience li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--corporate-amber);
}

@media (max-width: 768px) {
  .founders-grid {
    grid-template-columns: 1fr;
  }
  
  .founder-card {
    padding: 30px 20px;
  }
}

/* Contact Page Styles */
.contact-hero {
  background: linear-gradient(135deg, var(--corporate-red), var(--corporate-amber));
}

.contact-section {
  padding: 80px 20px;
  background: #ffffff;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info h3,
.contact-form h3 {
  font-size: 2em;
  color: var(--corporate-text-h2);
  margin-bottom: 30px;
}

.contact-methods {
  display: grid;
  gap: 30px;
}

.contact-method h4 {
  font-size: 1.2em;
  color: var(--corporate-text-h2);
  margin-bottom: 10px;
}

.contact-method a {
  color: var(--corporate-text-dark);
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-method a:hover {
  color: var(--corporate-text-h2);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--corporate-text-dark);
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #eee;
  border-radius: 4px;
  font-size: 1em;
  transition: border-color 0.2s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--corporate-amber);
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

/* Add to index.css */
.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.footer-cta {
  margin-bottom: 30px;
  text-align: center;
}

.logo-image {
  height: 40px;
  width: auto;
  display: block;
}

@media (max-width: 768px) {
  .logo-image {
    height: 30px;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
}

.logo-container span {
  color: var(--corporate-text-dark);
  font-weight: 600;
  display: inline-block;
  margin: 0;
}

@media (max-width: 768px) {
  .logo-image {
    height: 30px;
  }
  
  .logo-container {
    gap: 8px;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
}

.logo-container span {
  color: var(--corporate-text-dark);
  font-weight: 600;
  display: inline-block;
  margin: 0;
}

/* Keyframe Animation for Border Color Transition */
@keyframes borderColorLoop {
  0% {
    border-color: var(--corporate-red);
  }
  25% {
    border-color: var(--corporate-amber);
  }
  50% {
    border-color: var(--corporate-green);
  }
  75% {
    border-color: var(--corporate-blue);
  }
  100% {
    border-color: var(--corporate-red);
  }
}